import React, { createContext, useContext, useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Box, Button, Center, Flex, Spinner, VStack } from "@chakra-ui/react";
import Settings from "./pages/Settings";
import NavBar from "./components/NavBar";
import { wait } from "@testing-library/user-event/dist/utils";
import Newsletter from "./pages/Newsletter";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserData, User } from "./Api";
import Landing from "./Landing";


interface AppContextProps {
  user?: User | null;
  setUser?: (user: User | null) => void;
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
  settings?: boolean;
  setSettings?: (settings: boolean) => void;
  updateUser?: () => void;
};

const AppContext = createContext<AppContextProps>({});
export const useAppContext = () => useContext(AppContext);

function App() {
  const {isAuthenticated, loginWithRedirect, getAccessTokenSilently, isLoading} = useAuth0();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState(false);

  const updateUser = async () => {
    const token = await getAccessTokenSilently({'authorizationParams': {'audience': 'https://dev-wsgdkyywxzma55pn.us.auth0.com/api/v2/'}});
    if (token === undefined) {
      return;
    }
    const newUser = await getUserData(token);
    setUser(newUser);
  }

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        setLoading(true);
        await updateUser();
      }
      setLoading(false);
    })();
  }, [isAuthenticated]);

  return (
    <AppContext.Provider value={{user, setUser, loading, setLoading, settings, setSettings, updateUser}}>
      <Box>
        <NavBar/>
        <Center>
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            mt={"5vh"}
            p={4}
            maxW={"80vw"}
          >
            {
            (loading) ? 
              <Spinner /> :
              (isAuthenticated 
              ? (
                  user === null || settings ? 
                    <Settings/>
                    : <Newsletter/>
              ) 
              : (
                <Landing/>
              ))
            }
          </Flex>
        </Center>
      </Box>
    </AppContext.Provider>
  );
}

export default App;
