import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  Heading,
  Text,
  Link,
  Image,
  Flex,
  Divider,
  useMediaQuery,
  Stack,
} from "@chakra-ui/react";
import { useAppContext } from "../App";
import { useAuth0 } from "@auth0/auth0-react";
import { signUp, User } from "../Api";
import NewsletterCarousel, { NewsletterJSONType, SectionType } from "../components/NewsletterCarousel";

const Newsletter: React.FC = () => {
  const {
    user,
    setUser,
    loading,
    setLoading,
    settings,
    setSettings,
    updateUser,
  } = useAppContext();

  const [name, setName] = useState(user?.name ?? "");
  const [phone, setPhone] = useState(user?.phone ?? "");
  const [interests, setInterests] = useState(user?.interests ?? "");
  const [textNotifications, setTextNotifications] = useState(
    user?.text_notifications ?? true
  );

  const [buttonLoading, setButtonLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const handleSubmit = async () => {
    setButtonLoading(true);
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: "https://dev-wsgdkyywxzma55pn.us.auth0.com/api/v2/",
      },
    });

    if (token === undefined) {
      return;
    }
    // console.log( {name: name, phone: phone, interests: interests})
    await signUp(token, {
      name: name,
      phone: phone,
      interests: interests,
      text_notifications: textNotifications,
    });
    await updateUser?.();
    setButtonLoading(false);
  };

  const [isMobile] = useMediaQuery("(max-width: 768px)");

  // TODO: better typing of newsletter_data
  const renderNewsletterContent = (newsletter_data: any, isMobile: boolean) => {
    const newsletterJSON: NewsletterJSONType = JSON.parse(newsletter_data);

    return newsletterJSON.sections.map((section: SectionType) => (
      <>
        <NewsletterCarousel
          section={section}
          isMobile={isMobile}
        />
        <Box width="100%">
          <Divider />
        </Box>
      </>
    ));
  };

  return (
    <VStack spacing={4} width="100%" mx="auto" justifyContent={"center"}>
      {user?.newsletter_data === undefined ? (
        <p>
          We are curating your first newsletter! We'll text you when it's ready.
        </p>
      ) : (
        <>
          {renderNewsletterContent(user.newsletter_data, isMobile)}
          {/* <a href={user?.newsletter}>
            <Button>View On Newsletter</Button>
          </a> */}
        </>
      )}

      <FormControl id="info">
        <FormLabel>
          Wanna change what tomorrow's newsletter looks like? Update your
          interests!
        </FormLabel>
        <Textarea
          value={interests}
          onChange={(e) => setInterests(e.target.value)}
          placeholder="I want to learn about battery tech and stay up to date on March Maddness"
        />
      </FormControl>

      <Button
        backgroundColor="#0671f5"
        color="white"
        onClick={handleSubmit}
        isLoading={buttonLoading}
        loadingText="Updating..."
      >
        {"Update"}
      </Button>
    </VStack>
  );
};

export default Newsletter;
