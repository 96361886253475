import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  Input,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { signUp, User, getUserId, getUserData, runPipelineForUser } from "../Api";
import { useAppContext } from "../App";

const Settings: React.FC = () => {
  const {user, setUser, loading, setLoading, settings, setSettings, updateUser} = useAppContext();

  const [name, setName] = useState(user?.name ?? "");
  const [phone, setPhone] = useState(user?.phone ?? "");
  const [interests, setInterests] = useState(user?.interests ?? "");
  const [textNotifications, setTextNotifications] = useState(user?.text_notifications ?? true);

  
  const [buttonLoading, setButtonLoading] = useState(false);
  const {getAccessTokenSilently} = useAuth0();
  const handleSubmit = async () => {
    setButtonLoading(true);
    const token = await getAccessTokenSilently(
      {'authorizationParams': {'audience': 'https://dev-wsgdkyywxzma55pn.us.auth0.com/api/v2/'}}
      );
    var firstTimeUser = false;
    if (token === undefined) {
      return;
    }

    if (user == null) {
      firstTimeUser = true;
    }

    await signUp(token, {name: name, phone: phone, interests: interests, text_notifications: textNotifications})
    await updateUser?.();
    //if first time, also run the pipeline for this user
    if (firstTimeUser) {
      await runPipelineForUser(token);
    }
    setButtonLoading(false);

    setSettings?.(false);
  };

  return (
    <VStack
      spacing={4}
      width="100%"
      maxWidth="400px"
      mx="auto"
      justifyContent={"center"}
    >
      <FormControl id="info">
        <FormLabel>Name</FormLabel>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Sam Altman"
        />
      </FormControl>
      <FormControl id="info">
        <FormLabel>Phone Number</FormLabel>
        <Input
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder="(123) 456-7890"
        />
      </FormControl>
      <FormControl id="info">
        <FormLabel>What kind of news would you want to see on your personal newsletter? Feel free to write anything!</FormLabel>
        <Textarea
          value={interests}
          onChange={(e) => setInterests(e.target.value)}
          placeholder="I want to learn about battery tech and stay up to date on March Maddness"
        />
      </FormControl>
      <FormControl id="info" display='flex' alignItems='center'>
        <FormLabel mb='0' >Enable text notifications</FormLabel>
        <Switch
          isChecked={textNotifications}
          defaultChecked={true}
          // onChange={(e) => setTextNotifications(e.target.value)}
          onChange={(e) => setTextNotifications(!textNotifications)}
        />
      </FormControl>
      
      <HStack>
        <Button
          backgroundColor="#0671f5"
          color="white"
          onClick={handleSubmit}
          isLoading={buttonLoading}
          loadingText= {user === null ? "Generating Newsletter" : "Loading"}
        >
          {user === null ? "Confirm Settings" : "Update"}
        </Button>
      </HStack>
    </VStack>
  );
};

export default Settings;
