import React from "react";
import {
  Flex,
  Spacer,
  Button,
  useColorMode,
  IconButton,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { useAppContext } from "../App";

const Header: React.FC = () => {
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();
  const { colorMode, toggleColorMode } = useColorMode();
  const { user, setSettings, settings } = useAppContext();

  const handleLogout = () => {
    logout();
  };

  return (
    <Flex
      as="header"
      width="100%"
      height="60px"
      alignItems="center"
      paddingLeft={4}
      paddingRight={4}
    >
      <HStack>
        <Text fontSize="xl" mr={2}>
          🍫
        </Text>
        <Text fontWeight="bold">Bites AI</Text>
      </HStack>
      <Spacer />

      <HStack>
        <IconButton
          aria-label="Toggle dark mode"
          icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
          onClick={toggleColorMode}
        />
        {isAuthenticated ? (

          <>
            <Button
              onClick={handleLogout}
            >
              Logout
            </Button>

            {user && (
              <Button onClick={() => setSettings?.(!settings)}>
                {settings ? "Newsletter" : "Settings"}
              </Button>
            )}
            </>
        ) : (
          <>
           <Button
              onClick={() => {loginWithRedirect({'authorizationParams': {'audience': 'https://dev-wsgdkyywxzma55pn.us.auth0.com/api/v2/'}})}}                  
            >
              Log In
            </Button>
          </>
        )}
    </HStack>
    </Flex>
  );
};

export default Header;
