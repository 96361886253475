import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
// import { Stars  } from '@react-three/drei';
import { useGLTF } from "@react-three/drei/core/useGLTF";
import appleModel from "./apple-with-a-bite/source/Apple-with-bite-EMBED.gltf";
import { useFrame } from "@react-three/fiber";
import {
  Flex,
  Spacer,
  Button,
  useColorMode,
  IconButton,
  HStack,
  VStack,
  Text,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";

var rotation_speed = 0.01;

function Model() {
  const gltf = useGLTF(appleModel, true);

  // Ref to manipulate the model
  const mesh = React.useRef({ rotation: { y: 0 } });

  // Use the frame hook to rotate the model
  useFrame(({ clock }) => {
    if (mesh.current) {
      mesh.current.rotation.y += rotation_speed;
    }
  });

  const scale = [3.5, 3.5, 3.5]; // or whatever scale you want
  const position = [0, -2.8, -0.5]; // adjust the z-value as needed to bring the model "closer"

  if (gltf instanceof Array) {
    return null;
  } else {
    return (
      <primitive
        object={gltf.scene}
        position={position}
        scale={scale}
        ref={mesh}
      />
    );
  }
}

function Landing() {
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();

  return (
    <div>
      <div style={{ width: "100vw", height: "10vh" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Canvas style={{ height: "32vh", position: "relative" }}>
            {/* <PerspectiveCamera makeDefault position={[0, 0, 5]} /> */}
            <ambientLight />
            {/* <Stars /> */}
            <Suspense>
              <Model />
            </Suspense>
          </Canvas>
          <Text
            style={{
              position: "absolute",
              zIndex: 1,
              fontSize: "4.5em",
              marginLeft: "0em",
            }}
            onMouseEnter={() => rotation_speed = -rotation_speed}
          >
            Bites
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "20vh",
          }}
        >
          <Text
            style={{
              fontSize: "1.3em",
              margin: "1em",
              textAlign: "center",
              width: "100%",
              padding: '.3em'
            }}
          >
            A daily newsletter, curated just for you.
          </Text>{" "}
          <Button
            onClick={() => {
              loginWithRedirect({'authorizationParams': {'audience': 'https://dev-wsgdkyywxzma55pn.us.auth0.com/api/v2/'}})
            }}
          >
            Sign Up (It's free!)
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Landing;
