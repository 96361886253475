export type User = {
  name: string;
  phone: string;
  interests?: string;
  newsletter?: string;
  newsletter_data?: string;   // most definitely is NOT a string
  text_notifications?: boolean;
};

//old backend: https://backend-biejksy5za-uw.a.run.app
//TODO: right, the backend in cloud rin allows unathenticated invocations, we should change it to require authentication
var backend_url = 'https://backend-rfp2sgih3q-wl.a.run.app'
//MAKE SURE THESE VARS ARE BOTH FALSE BEFORE PUSHING
const local_backend = false
const local_docker_backend = false
if (local_backend) {
  backend_url = 'http://127.0.0.1:8080'
  if (local_docker_backend) {
    backend_url = "http://localhost:5001"
  }
}


export async function signUp(token: string, user: User): Promise<string> {
  const response = await fetch(backend_url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token
    },
    body: JSON.stringify(user)
  });

  const result = await response.json();
  return result.message;
}

export async function updateUserInterests(token: string, interests: string): Promise<string> {
  const response = await fetch(backend_url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token
    },
    body: JSON.stringify({ interests: interests })
  });

  const result = await response.json();
  return result.message;
}

export async function getUserData(token: string): Promise<User | null> {
  const response = await fetch(backend_url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token
    },
  });

  const result = await response.json();
  if (result.status === 'success') {
    return result.user;
  } else {
    return null;
  }
}

export async function getUserId(token: string) {
  const response = await fetch(backend_url + "/user_id", {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token
    },
  });

  const result = await response.json();
  if (result.status === 'success') {
    return result.user_id;
  } else {
    return null;
  }
}

//hit flask endpoint that hits google cloud function endpoint to run pipeline for user. 
//a bit redundant but better than storing google service acct credentials in the frontend
export async function runPipelineForUser(token: string) {
  const response = await fetch(backend_url + "/run_pipeline", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + token
    },
  });

  const result = await response.json();
  return result;
}
