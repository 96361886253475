import React, { useState } from "react";
import {
  VStack,
  Heading,
  Stack,
  Box,
  Image,
  Text,
  Button,
  Link,
  Divider,
  IconButton,
  HStack,
  AspectRatio,
} from "@chakra-ui/react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

type ArticleType = {
  img_url?: string;
  name: string;
  summary: string;
  read_more_url: string;
};

export type SectionType = {
  section_header: string;
  articles: ArticleType[];
};

export type NewsletterJSONType = {
  sections: SectionType[];
};

type NewsletterCarouselProps = {
  section: SectionType;
  isMobile: boolean;
};

const NewsletterCarousel: React.FC<NewsletterCarouselProps> = ({
  section,
  isMobile,
}) => {
  const [currentArticle, setCurrentArticle] = useState(0);

  const prevArticle = () => {
    setCurrentArticle((oldCurrentArticle) =>
      oldCurrentArticle === 0
        ? section.articles.length - 1
        : oldCurrentArticle - 1
    );
  };

  const nextArticle = () => {
    setCurrentArticle((oldCurrentArticle) =>
      oldCurrentArticle === section.articles.length - 1
        ? 0
        : oldCurrentArticle + 1
    );
  };

  const article = section.articles[currentArticle];

  //   TODO: Use chakra breakpoints instead of isMobile
  return (
    <VStack
      key={section.section_header}
      spacing={6}
      width="100%"
      alignItems="start"
    >
      <Heading size="lg" fontWeight="medium">
        {section.section_header}
      </Heading>

      <Stack
        key={currentArticle}
        width="100%"
        alignItems="start"
        direction={isMobile ? "column" : "row"}
        spacing={4}
      >
        {article.img_url && (
          <Box flex="2">
            <AspectRatio ratio={4 / 3} width={isMobile ? "65vw" : ""}>
              <Image src={article.img_url} maxHeight="35vh" objectFit="cover" />
            </AspectRatio>
          </Box>
        )}
        <Box flex="3">
          <Heading size="sm" mb={2}>
            {article.name}
          </Heading>
          <Text mb={2}>{article.summary}</Text>
          <Button as={Link} href={article.read_more_url} isExternal>
            Read more
          </Button>
        </Box>
      </Stack>

      <HStack spacing={2}>
        <IconButton
          aria-label="Previous article"
          icon={<FiArrowLeft />}
          onClick={prevArticle}
        />
        {section.articles.map((_, i) => (
          <Box
            key={i}
            width="1em"
            height="1em"
            borderRadius="50%"
            bgColor={i === currentArticle ? "blue.500" : "gray.200"}
            onClick={() => setCurrentArticle(i)}
            _hover={{ cursor: "pointer" }}
          />
        ))}
        <IconButton
          aria-label="Next article"
          icon={<FiArrowRight />}
          onClick={nextArticle}
        />
      </HStack>
    </VStack>
  );
};

export default NewsletterCarousel;
