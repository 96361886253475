import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";

ReactDOM.render(
  <Auth0Provider
    domain="dev-wsgdkyywxzma55pn.us.auth0.com"
    clientId="C4VXf6B6ofpgLkYLzeXUQr6Ixs2SmcUA"
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </Auth0Provider>,
  document.getElementById("root")
);
